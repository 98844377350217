<template>
  <div>
    <img
      class="h-10 w-full object-contain"
      src="@/assets/loader.gif"
    >
  </div>
</template>
<script>
export default {
  name: "Loader",
};
</script>

<style scoped>
</style>