<template>
  <div class="flex items-center sm:px-20 px-10 h-full">
    <img
      class="h-10 object-contain "
      src="@/assets/Lipalink-Logo.png"
    >
    <div
      class="
        flex
        gap-20
        pl-44
        h-full justify-end w-full
        items-center
        text-white
        font-medium
        text-xs
      "
    >
      <a href="https://lipalink.com" class="cursor-pointer hover:text-primary">
        Home
      </a>
      <!-- <p class="cursor-pointer hover:text-primary">
        Security & Privacy
      </p>
      <p class="cursor-pointer hover:text-primary">
        Contact Us
      </p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthNavBar",
};
</script>