<template>
  <div class="max-h-screen">
    <transition name="fade">
      <div v-if="loading" class="absolute inset-0 z-50 bg-black bg-opacity-50">
        <loader class="absolute center-h-v" />
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="showEnrollementSuccess"
        class="bg-black fixed bg-opacity-70 inset-0 z-50 h-screen w-full"
      >
        <div
          class="fixed center-h-v bg-white rounded-md sm:w-1/3 w-full shadow-2xl"
        >
          <div class="flex justify-end items-center p-3">
            <img
              class="h-5 object-contain bg-buttonColor rounded-md cursor-pointer hover:shadow-lg"
              src="@/assets/icons/cross-white.png"
              @click="showEnrollementSuccess = false"
            />
          </div>
          <div>
            <div class="px-10 pb-9">
              <img
                class="h-10 mx-auto object-contain"
                src="@/assets/icons/check.png"
              />
              <p class="text-center pt-5">
                We have received your request to enroll for Lipalink. We will
                review the request then get back to you
              </p>
            </div>
          </div>
        </div>
      </div>
      >
    </transition>
    <div class="h-screen-10 w-full bg-navbarColor">
      <auth-nav-bar />
    </div>
    <div class="h-screen-90 grid sm:grid-cols-3 grid-cols-1">
      <div
        class="flex sm:justify-center justify-start sm:mt-0 mt-10 flex-col sm:mx-20 mx-5 col-span-2"
      >
        <div class="text-textColorGray">
          <h1 class="text-2xl font-semibold pb-2">Register your company</h1>
          <transition name="fade">
            <div
              v-if="error"
              class="text-xs border-1.5 border-red-400 mb- flex items-center p-2 gap-4 sm:w-4/6"
            >
              <img class="h-5" src="@/assets/icons/cross.png" />
              <p class="font-bold">{{ error }}</p>
            </div>
          </transition>
          <form @submit.prevent="enroll" class="w-full pt-5">
            <div class="grid grid-cols-2 sm:gap-5 gap-8 items-center">
              <input
                required
                type="text"
                v-model="data.OrganizationName"
                placeholder="Company Name*"
                class="border-0.5 border-inputBorder p-2 text-sm rounded-lg"
              />
              <input
                required
                type="text"
                v-model="data.OrganizationAddress"
                placeholder="Physical Loaction*"
                class="border-0.5 border-inputBorder p-2 text-sm rounded-lg"
              />
              <input
                required
                type="email"
                v-model="data.OrganizationEmail"
                placeholder="Company Contact Email*"
                class="border-0.5 border-inputBorder p-2 text-sm rounded-lg"
              />
              <div
                class="w-full flex items-center gap-2 p-2 text-sm rounded-lg border-0.5 border-inputBorder"
              >
                <p>+254</p>
                <input
                  required
                  type="tel"
                  maxlength="9"
                  v-model="data.OrganizationContact"
                  placeholder="Company Phone* +254 000 000 000"
                  class="w-full focus:outline-none"
                />
              </div>

              <div class="text-sm flex flex-col">
                <label for="File">Business Permit*</label>
                <input
                  required
                  type="file"
                  ref="permitfile"
                  @change="onPermitfileChange"
                  placeholder="Business Permit"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  class="py-1 text-xs"
                />
              </div>

              <div class="text-sm flex flex-col">
                <label for="File">Mpesa Acknowledgement*</label>
                <input
                  required
                  type="file"
                  ref="mpesafile"
                  @change="onMpesafileChange"
                  placeholder="Mpesa Acknowledgement"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  class="py-1 text-xs"
                />
              </div>
              <div class="text-sm flex flex-col">
                <label for="File">CRI2*</label>
                <input
                  required
                  type="file"
                  ref="cr12file"
                  @change="onCr12fileChange"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  placeholder="CRI2"
                  class="py-1 text-xs"
                />
              </div>
              <div class="text-sm flex flex-col">
                <label for="File">Company Registration*</label>
                <input
                  required
                  type="file"
                  ref="registrationfile"
                  @change="onRegistrationfileChange"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  placeholder="Company Registration"
                  class="py-1 text-xs"
                />
              </div>
              <div class="text-sm flex flex-col">
                <label for="File">Company Logo*</label>
                <input
                  required
                  type="file"
                  ref="logoFile"
                  accept=".jpg, .jpeg, .png"
                  @change="onLogoFileChange"
                  placeholder="Company Logo"
                  class="py-1 text-xs"
                />
              </div>
            </div>
            <div class="pt-5 text-sm font-semibold">
              <input required type="checkbox" />
              <label>
                Accept our
                <a href="#" class="text-buttonColor">Terms and Conditions</a>
              </label>
            </div>

            <br />
            <button
              type="submit"
              class="bg-buttonColor hover:bg-primary text-white sm:w-1/2 w-full p-2 font-semibold rounded"
            >
              Submit
            </button>
            <!-- <p class="pt-8 text-sm font-semibold" @click="goToLogin">
              Already have an account?
              <a class="text-buttonColor" href="#">Login</a>
            </p> -->
          </form>
        </div>
      </div>
      <div class="bg-gradient h-full col-span-1 relative sm:block hidden">
        <img
          src="@/assets/images/enroll.png"
          class="h-3/4 right-64 w-full object-contain center-h-v absolute"
        />
        <div class="absolute bottom-0 w-full mb-8 text-white">
          <p class="text-center text-xs opacity-70">
            &copy; Copyright {{ new Date().getFullYear() }}.
            <a class="text-black" href="https://sepia.co.ke"
              >Sepia consulting</a
            >
            All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthNavBar from "../../components/auth/authNavbar.vue";
import Loader from "../../components/loader/loader.vue";
import axios from "axios";
export default {
  name: "Enroll",
  components: { AuthNavBar, Loader },
  data() {
    return {
      data: {
        OrganizationName: null,
        OrganizationAddress: null,
        OrganizationContact: null,
        OrganizationEmail: null,
        logoFile: null,
        permitfile: null,
        mpesafile: null,
        cr12file: null,
        registrationfile: null,
      },
      error: null,
      loading: false,
      showEnrollementSuccess: false,
    };
  },
  methods: {
    formattNumber() {},
    onPermitfileChange() {
      this.data.permitfile = this.$refs.permitfile.files[0];
    },
    onMpesafileChange() {
      this.data.mpesafile = this.$refs.mpesafile.files[0];
    },
    onCr12fileChange() {
      this.data.cr12file = this.$refs.cr12file.files[0];
    },
    onRegistrationfileChange() {
      this.data.registrationfile = this.$refs.registrationfile.files[0];
    },
    onLogoFileChange() {
      this.data.logoFile = this.$refs.logoFile.files[0];
    },
    goToLogin() {
      this.$router.push({
        name: "login",
      });
    },
    enroll() {
      this.loading = true;
      const formData = new FormData();
      formData.append("OrganizationName", this.data.OrganizationName);
      formData.append("OrganizationAddress", this.data.OrganizationAddress);
      formData.append(
        "OrganizationContact",
        `254${this.data.OrganizationContact}`
      );
      formData.append("OrganizationEmail", this.data.OrganizationEmail);
      formData.append("logoFile", this.data.logoFile);
      formData.append("permitfile", this.data.permitfile);
      formData.append("mpesafile", this.data.mpesafile);
      formData.append("cr12file", this.data.cr12file);
      formData.append("registrationfile", this.data.registrationfile);
      axios
        .post("organization/create", formData)
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.showEnrollementSuccess = true;
          this.data = {
            OrganizationName: null,
            OrganizationAddress: null,
            OrganizationContact: null,
            OrganizationEmail: null,
            logoFile: null,
            permitfile: null,
            mpesafile: null,
            cr12file: null,
            registrationfile: null,
          };
        })
        .catch((error) => {
          this.error = error.response.data.Message;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.center-h-v {
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.h-screen-10 {
  height: 10vh;
}
.h-screen-90 {
  height: 90vh;
}
.bg-gradient {
  background: rgb(252, 186, 30);
  background: -moz-linear-gradient(
    135deg,
    rgba(252, 186, 30, 1) 0%,
    rgba(226, 122, 1, 1) 100%
  );
  background: -webkit-linear-gradient(
    135deg,
    rgba(252, 186, 30, 1) 0%,
    rgba(226, 122, 1, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(252, 186, 30, 1) 0%,
    rgba(226, 122, 1, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcba1e",endColorstr="#e27a01",GradientType=1);
}
</style>
